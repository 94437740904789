/**
 * Layout component
 * to wrap the login pages (i.e. does not have same header and footer as the rest of the site)
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';
import PageBackground from './page-background';
import CookieConsent from 'react-cookie-consent';

import Footer from './footer';
import '../styles/default.css';
import {ReactNode} from 'react';
import NavBar from './nav-bar';
interface LayoutProps {
  children: ReactNode[] | ReactNode;
}
const LayoutLogin = ({children}: LayoutProps) => (
  <>
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      declineButtonText="Decline"
      enableDeclineButton={true}
      hideOnAccept={true}
      cookieName="gatsby-gdpr-google-analytics"
      sameSite="none"
      contentStyle={{fontSize: 14}}>
      We use tools. such as cookies, to enable essential services and
      functionality on our site and collect data on how visitors interact with
      our site, products and services. By clicking Accept or continuing to use
      this site, you agree to our use of these tools for advertising and
      analytics. <Link to="/privacy">Learn More</Link>
    </CookieConsent>

    <PageBackground />
    <div
      style={{
        padding: '0 1rem',
      }}>
      <main>{children}</main>
    </div>
    <Footer />
  </>
);

LayoutLogin.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutLogin;
