import React from 'react';

import Layout from '../components/layout';
import Profile from '../components/profile';
import Login from '../components/login';
import {Router} from '@reach/router';
import PrivateRoute from '../components/private-route';

/** Set up client routes for dynamic pages */
/* TODO - consider moving the layout wrapper into the pages
   so the private route does not display the header when in login mode 
*/
const App = () => (
//   <Layout>
    <Router basepath="/app">
      <PrivateRoute path="/profile" component={Profile}/>
      {/* <Login path="/login" /> */}
    </Router>
//   </Layout>
);

export default App;
