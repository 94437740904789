import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import {getAuth, uiConfig} from '../services/firebase';

interface LoginProps {
  path?: any;
}
const Login = ({}: LoginProps) => {
  const auth = getAuth();
  if (!auth) {
    return null;
  }
  return <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />;
};

export default Login;
