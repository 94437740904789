import React from 'react';
import {useUser} from '../containers/user-provider';
import Avatar from './avatar';

const Profile = () => {
  const {displayName: name, email, photoURL} = useUser();
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '1rem 1rem',
      }}>
      <h1 style={{textAlign: 'center'}}>{name}</h1>
      <h2 style={{textAlign: 'center'}}>{email}</h2>
      <Avatar photoURL={photoURL} />
    </div>
  );
};

export default Profile;
