import React from "react";
interface AvatarProps{
    photoURL: string;
}
const holdingImg = 'https://res.cloudinary.com/dqcsk8rsc/image/upload/v1577268053/avatar-1-bitmoji_upgwhc.png';
const Avatar = ({photoURL}: AvatarProps) => {
    return (
        <div
        style={{
          background: `url(${photoURL || holdingImg})  no-repeat center center`,
          backgroundSize: "cover",
          height: "200px",
          width: "200px",
          borderRadius: 100,
          
        }}
        className="border border-blue-300"
      ></div>
    );
}
export default Avatar;