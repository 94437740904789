import React from 'react';
import {useIsLoggedIn} from '../containers/user-provider';
import Layout from './layout';
import LayoutLogin from './layout-login';
import Login from './login';
import PageBackground from './page-background';

interface PrivateRouteProps {
  component: any;
  location?: any;
  path: string;
}
/** intercepts the wrapped component with a isLoggedIn check
 * and displays the login component instead if check fails
 */
const PrivateRoute = ({
  component: Component,
  location,
  ...rest
}: PrivateRouteProps) => {
  const isLoggedIn = useIsLoggedIn();
  if (!isLoggedIn && location.pathname !== `/app/login`) {
    console.log('not logged on..');
    return (
      <LayoutLogin>
        <Login />
      </LayoutLogin>
    );
  }

  return (
    <Layout>
      <Component {...rest} />;
    </Layout>
  );
};

export default PrivateRoute;
